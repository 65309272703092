/*
 * @Description:
 * @Author: fal
 * @Date: 2024-10-28 16:52:50
 * @FilePath: \pc.ui\src\project\router\modules\project.js
 * @LastEditTime: 2025-01-02 09:42:27
 */
export default [
  // 工程进度
  {
    path: '/project/procedure',
    name: 'procedure',
    component: () => import('@/project/views/project/proceed/procedure'),
  },
  // 工程资料
  {
    path: '/project/doc',
    name: 'projectDoc',
    component: () => import('@/project/views/project/proceed/doc'),
  },
  // 人员
  {
    path: '/project/material/person',
    name: 'materialperson',
    component: () => import('@/project/views/project/material/person'),
  },
  // 机械
  {
    path: '/project/material/machine',
    name: 'materialPersonDetail',
    component: () => import('@/project/views/project/material/machine'),
  },
  // 材料
  {
    path: '/project/material/stuff',
    name: 'materialStuff',
    component: () => import('@/project/views/project/material/stuff'),
  },
  // 材料合同
  {
    path: '/project/contract/material',
    name: 'contractMaterial',
    component: () => import('@/project/views/project/contract/material'),
  },
  // 机械合同
  {
    path: '/project/contract/machine',
    name: 'contractMachine',
    component: () => import('@/project/views/project/contract/machine'),
  },
  // 劳务合同
  {
    path: '/project/contract/depart',
    name: 'contractDepart',
    component: () => import('@/project/views/project/contract/depart'),
  },
  // 其他合同
  {
    path: '/project/contract/other',
    name: 'contractOther',
    component: () => import('@/project/views/project/contract/other'),
  },
  // 工程造价
  // 清标对比
  {
    path: '/project/cost/clear',
    name: 'costClear',
    component: () => import('@/project/views/project/cost/clear'),
  },
  // 请款金额
  {
    path: '/project/cost/pleaseMoney',
    name: 'costPleaseMoney',
    component: () => import('@/project/views/project/cost/pleaseMoney'),
  },
  // 结算金额
  {
    path: '/project/cost/count',
    name: 'costCount',
    component: () => import('@/project/views/project/cost/count'),
  },
  // 工程预警
  // 进度预警
  {
    path: '/project/warn/process',
    name: 'warnProcess',
    component: () => import('@/project/views/project/warn/process'),
  },
  // 造价预警
  {
    path: '/project/warn/cost',
    name: 'warnCost',
    component: () => import('@/project/views/project/warn/cost'),
  },
  // 人员预警
  {
    path: '/project/warn/person',
    name: 'warnPerson',
    component: () => import('@/project/views/project/warn/person'),
  },
  // 材料预警
  {
    path: '/project/warn/material',
    name: 'warnMaterial',
    component: () => import('@/project/views/project/warn/material'),
  },
  // 机械预警
  {
    path: '/project/warn/machine',
    name: 'warnMachine',
    component: () => import('@/project/views/project/warn/machine'),
  },
  // 成本预警
  {
    path: '/project/warn/costWarning',
    name: 'costWarning',
    component: () => import('@/project/views/project/warn/costWarning'),
  },
  // 工程成本
  // 预算成本清单
  {
    path: '/project/prime/amount',
    name: 'budgetCostList',
    component: () => import('@/project/views/project/prime/budgetCostList'),
  },
  // 报销成本
  {
    path: '/project/prime/other',
    name: 'reimbursementCost',
    component: () => import('@/project/views/project/prime/reimbursementCost'),
  },
  // 成本汇总
  {
    path: '/project/prime/costSummary',
    name: 'primeSummary',
    component: () => import('@/project/views/project/prime/costSummary'),
  },
  // 税务
  {
    path: '/project/tax',
    name: 'tax',
    component: () => import('@/project/views/project/tax/tax'),
  },
  // 权限管理
  {
    path: '/project/permission',
    name: 'permission',
    component: () => import('@/project/views/project/permission/permission'),
  },
  // 项目概况
  {
    path: '/project/brife',
    name: 'brife',
    component: () => import('@/project/views/project/brife/brife'),
  },
  // 项目筹划
  {
    path: '/project/plan',
    name: 'plan',
    component: () => import('@/project/views/project/plan/plan'),
  },
  // 安全日志
  {
    path: '/project/dictionary/safe',
    name: 'safeDictionary',
    component: () => import('@/project/views/project/dictionary/safe'),
  },
  // 施工日志
  {
    path: '/project/dictionary/work',
    name: 'workDictionary',
    component: () => import('@/project/views/project/dictionary/work'),
  },
  // 日报
  {
    path: '/project/dictionary/daily',
    name: 'dailyDictionary',
    component: () => import('@/project/views/project/dictionary/daily'),
  },
  // 待办事项
  {
    path: '/project/twaitCase',
    name: 'twaitCase',
    component: () => import('@/project/views/project/twaitCase/twaitCase'),
  },
  // 人才招聘
  {
    path: '/project/infoMag/hire',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/hire'),
  },
  // 工程劳务需求
  {
    path: '/project/infoMag/laborDemand',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/laborDemand'),
  },
  // 材料采购发布
  {
    path: '/project/infoMag/material',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/material'),
  },
  // 机械采购发布
  {
    path: '/project/infoMag/machine',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/machine'),
  },
  // 其他服务
  {
    path: '/project/infoMag/comprehensive',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/comprehensive'),
  },
  // 平台推送
  {
    path: '/project/infoMag/push',
    name: 'twaitCase',
    component: () => import('@/project/views/project/infoMag/push'),
  },
];
