import COS from './cos-js-sdk-v5.min.js';
import { newApiURL } from '../../config/index.js';
import { Loading } from 'element-ui';

// COS 配置信息
const COS_CONFIG = {
  SecretId: 'AKIDZK2ZzCE9qKaZLwJSM1YqvnR3YpuyumTy',
  SecretKey: 'ra6UggV8YoqfGd0GJMFxeO1S4XmnY6Sc',
  Bucket: 'project-dev-1315090369',
  Region: 'ap-chengdu',
};
// 路径前缀
const prefix = `https://${COS_CONFIG.Bucket}.cos.${COS_CONFIG.Region}.myqcloud.com/`;

/**
 * 下载文件（支持单个和多个文件）
 * @param {string|Array<string>} paths 文件路径（单个或多个）
 * @param {string} [filename] 下载后的文件名，可选传
 */
export function downloadFiles(paths, filename) {
  return new Promise((resolve, reject) => {
    const loadingInstance = Loading.service({
      lock: true,
      text: '正在下载中，请稍后...',
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.7)',
    });

    if (!paths) {
      loadingInstance.close();
      reject(new Error('No paths provided'));
      return;
    }
    if (typeof paths === 'string') {
      paths = paths.split(',').map((path) => path.trim());
    }
    if (Array.isArray(paths) && paths.length > 1) {
      // 如果是数组且有多个路径，调用 downloadMultipleFiles
      downloadMultipleFiles(paths)
        .then(resolve)
        .catch((err) => {
          loadingInstance.close();
          reject(err);
        })
        .finally(() => {
          loadingInstance.close();
        });
    } else {
      // 否则，调用 downloadFile
      const singlePath = Array.isArray(paths) ? paths[0] : paths;
      downloadSingleFile(singlePath, filename)
        .then(resolve)
        .catch((err) => {
          loadingInstance.close();
          reject(err);
        })
        .finally(() => {
          loadingInstance.close();
        });
    }
  });
}

/**
 * 下载单个文件
 * @param {string} url 文件路径 （全路径）
 * @param {string} [filename] 下载后的文件名，可选传
 * @returns {Promise} 返回下载结果
 */
function downloadSingleFile(url, filename) {
  return new Promise((resolve) => {
    const timestamp = new Date().getTime();
    const a = document.createElement('a');
    a.href = `${url}?t=${timestamp}`;
    if (a.href.includes('https://cdn.kejiankeji.com/')) {
      a.target = '_blank';
    }
    a.download = filename; // 设置下载文件的名称
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    resolve();
  });
}

/**
 * 下载腾讯云 COS 对象
 * @param {string} key 对象键（对象在存储桶中的唯一标识）
 * @param {string} [filename] 下载后的文件名，可选
 * @returns {Promise} 返回下载结果
 */
function downloadCosObject(key, filename) {
  // 去掉前缀，保留对象的键
  const objectKey = key.replace(prefix, ''); // 移除前缀
  return new Promise((resolve, reject) => {
    const cos = new COS({
      SecretId: COS_CONFIG.SecretId,
      SecretKey: COS_CONFIG.SecretKey,
    });

    cos.getObject(
      {
        Bucket: COS_CONFIG.Bucket,
        Region: COS_CONFIG.Region,
        Key: objectKey, // 使用修正后的 key
        DataType: 'blob',
      },
      function (err, data) {
        if (err) {
          reject(err);
          return;
        }

        // 创建 Blob URL
        const blob = new Blob([data.Body]);
        const downloadUrl = window.URL.createObjectURL(blob);

        downloadSingleFile(downloadUrl, filename || objectKey);
        resolve(data);
      }
    );
  });
}

/**
 * 多文件压缩下载
 * @param {Array<string>} keys 对象键数组（多个文件的 COS 路径）
 * @returns {Promise} 返回压缩下载结果
 */
function downloadMultipleFiles(keys) {
  return new Promise((resolve, reject) => {
    const timestamp = new Date().getTime();
    const zipFileName = `file${timestamp}.zip`;
    const zipPath = `/temporary/${zipFileName}`;

    const cos = new COS({
      SecretId: COS_CONFIG.SecretId,
      SecretKey: COS_CONFIG.SecretKey,
    });

    // 构建压缩任务请求
    const host = `${COS_CONFIG.Bucket}.ci.${COS_CONFIG.Region}.myqcloud.com/file_jobs`;
    const url = `https://${host}`;

    const keyConfig = keys.map((item) => ({ Key: item.replace(prefix, '') }));

    // 构建压缩任务配置
    const body = COS.util.json2xml({
      Request: {
        Tag: 'FileCompress',
        Operation: {
          FileCompressConfig: {
            Flatten: '1',
            Format: 'zip',
            KeyConfig: keyConfig,
          },
          Output: {
            Bucket: COS_CONFIG.Bucket,
            Region: COS_CONFIG.Region,
            Object: zipPath,
          },
          UserData: '',
        },
      },
    });

    // 提交压缩任务
    cos.request(
      {
        Method: 'POST',
        Key: 'file_jobs',
        Url: url,
        Body: body,
        ContentType: 'application/xml',
      },
      function (err, data) {
        console.log('压缩任务提交成功:', data);
        if (err) {
          reject(err);
          return;
        }

        const jobId = data.Response.JobsDetail.JobId; // 获取任务 ID

        // 查询压缩任务状态
        checkCompressionStatus(jobId, zipFileName, resolve, reject);
      }
    );
  });
}

/**
 * 查询压缩任务状态,一旦成功就开始下载
 * @param {Array<string>} keys 对象键数组（多个文件的 COS 路径）
 * @returns {Promise} 返回压缩下载结果
 */
function checkCompressionStatus(jobId, zipFileName, resolve, reject) {
  // 创建 COS 实例
  const cos = new COS({
    SecretId: COS_CONFIG.SecretId,
    SecretKey: COS_CONFIG.SecretKey,
  });

  // 使用 cos.request 查询压缩任务状态
  const checkStatus = () => {
    cos.request(
      {
        Method: 'GET', // 使用 GET 方法
        Key: `file_jobs/${jobId}`, // 使用任务 ID
        Url: `https://${COS_CONFIG.Bucket}.ci.${COS_CONFIG.Region}.myqcloud.com/file_jobs/${jobId}`,
        ContentType: 'application/xml', // 设置请求头
      },
      function (err, data) {
        if (err) {
          reject(err);
          return;
        }

        console.log('使用 cos.request 查询压缩任务状态 ', err, data);

        const status = data.Response.JobsDetail.State; // 获取任务状态
        if (status === 'Success') {
          const downloadUrl = `https://${COS_CONFIG.Bucket}.cos.${COS_CONFIG.Region}.myqcloud.com/temporary/${zipFileName}`;
          downloadSingleFile(downloadUrl, zipFileName);
          resolve(data);
        } else if (status === 'Failed') {
          this.$message.error('压缩任务失败');
          reject(new Error('压缩任务失败'));
        } else {
          // 继续查询状态
          setTimeout(checkStatus, 2000); // 每2秒查询一次
        }
      }
    );
  };

  checkStatus(); // 开始查询状态
}

/**
 * 删除文件
 * @param {Object} vue Vue实例
 * @param {string} url 文件路径
 * @returns {Promise} 返回压缩下载结果
 */
export function deleteFile(vue, paths) {
  if (!paths) return false;

  let src = [];
  if (Array.isArray(paths)) {
    // 如果 paths 是数组，直接处理
    src = paths.map((path) => path.trim());
  } else if (typeof paths === 'string') {
    // 如果 paths 是字符串，分割成数组
    src = paths.split(',').map((path) => path.trim());
  }

  if (src.length) {
    src = src.map((singlePath) => singlePath.replace(prefix, ''));
    console.log('src', src);
    deleteSingleFile(vue, src);
  }
}

/**
 * 删除单个文件
 * @param {Object} vue Vue实例
 * @param {Array} urls 路径数组
 * @returns {Promise} 返回结果
 */
export function deleteSingleFile(vue, urls) {
  // 如果urls数组为空，则直接返回
  if (!urls.length) return;

  return new Promise((resolve) => {
    vue.$request.post('/collective/file/del', { urls: urls }).then((res) => {
      // 请求成功后，resolve返回结果
      resolve(res);
    });
  });
}

/**
 * 获取公司列表
 * @param {Object} vue Vue实例
 * @param {string} type 请求参数 0.施工单位,1.材料供应商,2.机械供应商,3.劳务公司,4.咨询公司,5.其他咨询公司
 * @returns {Promise} 返回格式化后的公司列表
 */
export function getPriseList(vue, type) {
  return new Promise((resolve) => {
    vue.$request.post('/collective/select/priseList', { type: type }).then((res) => {
      resolve(res);
    });
  });
}

/**
 * 获取合同列表
 * @param {Object} vue Vue实例
 * @param {string} type 请求参数 类型 1.材料 2.机械 3.劳务4.其他
 * @returns {Promise} 返回格式化后的公司列表
 */
export function getContractList(vue, type) {
  return new Promise((resolve) => {
    vue.$request.post('/collective/select/contractList', { type: type }).then((res) => {
      resolve(res);
    });
  });
}

/**
 * 列表导出
 * @param {Object} vue Vue实例
 * @param {string} url 请求参数 接口地址
 * @param {string} data 请求参数 参数
 */
export function exportList(vue, url, data) {
  return new Promise((resolve) => {
    vue.$request.post(url, data).then((res) => {
      downloadFiles(res);
      // vue.$message.success('下载成功')
      resolve(res);
    });
  });
}

/**
 * 校验是否为数字，最多保留 n 位小数（必须大于0）
 * @param {string|number} value 要校验的值
 * @param {number} n 保留的小数位数
 * @returns {boolean} 校验结果
 */
export function isValidNumber(value, n) {
  if (value < 0) return false;
  const regex =
    n === 0
      ? /^-?\d+$/ // 如果 n 为 0，校验整数
      : new RegExp(`^-?\\d+(\\.\\d{1,${n}})?$`); // 否则，校验最多 n 位小数
  return regex.test(value);
}

/**
 * 校验是否为数字，最多保留 n 位小数（可以小于0）
 * @param {string|number} value 要校验的值
 * @param {number} n 保留的小数位数
 * @returns {boolean} 校验结果
 */
export function isValidNumber2(value, n) {
  const regex =
    n === 0
      ? /^-?\d+$/ // 如果 n 为 0，校验整数
      : new RegExp(`^-?\\d+(\\.\\d{1,${n}})?$`); // 否则，校验最多 n 位小数
  return regex.test(value);
}

/**
 * 校验手机号
 * @param {string} phone 要校验的手机号
 * @returns {boolean} 校验结果
 */
export function isValidPhoneNumber(phone) {
  const regex = /^1[3-9]\d{9}$/;
  return regex.test(phone);
}

/**
 * 校验身份证号
 * @param {string} idCard 要校验的身份证号
 * @returns {boolean} 校验结果
 */
export function isValidIDCard(idCard) {
  const regex = /^(?:\d{15}|\d{17}[\dXx])$/;
  return regex.test(idCard);
}

/**
 * 金额超过三位数的以逗号分隔显示
 * @param {string} number 金额
 * @param {string} n 保留n位小数
 * @returns {boolean} 结果
 */
export function formatCurrency(number, n = 2) {
  number = number ? parseFloat(number) : 0;
  if (isNaN(number) || number === 0) return number.toString();
  return number.toFixed(n).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,');
}

/**
 * 下载单个文件
 * @param {string} url 文件路径（非全路径）
 * @param {string} [filename] 下载后的文件名，可选传
 * @returns {Promise} 返回下载结果
 */
export function downloadKeyFile(url, filename) {
  const timestamp = new Date().getTime();
  const a = document.createElement('a');
  a.href = `${newApiURL}${url}?t=${timestamp}`;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * 获取环境选择列表
 * @param {Object} vue Vue实例
 * @param {Array} key 请求参数 settlement_modes结算方式,experience工作经历,education学历要求,wage薪资,age年龄,position_attribu求职类型,sex性别,job_status在职状态,person_number班组人数,sales_method售卖方式,star_rating星级,price价格,speciality专业,qualifications资质等级
 * @returns {Promise} 返回格式化后的公司列表
 */
export function getConditionList(vue, key) {
  return new Promise((resolve) => {
    vue.$request.post('/collective/select/conditionList', { key: key }).then((res) => {
      resolve(res);
    });
  });
}

/**
 * 校验权限
 * @param {string} code 权限码
 * @returns {boolean} 结果
 * */
export function checkPermission(code) {
  if (!code) return true;
  const authList = JSON.parse(sessionStorage.getItem('rule') || []);
  if (!authList.length) return true;
  return authList.find((item) => item == code) !== undefined;
}

/**
 * 节流
 * @param {function} fn 方法
 * @returns {Number} delay 延迟
 * */
export function throttle(fn, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    if (!timeout) {
      fn.apply(context, args);
      timeout = setTimeout(() => {
        timeout = null;
      }, delay || 1000);
    }
  };
}
