<!--
 * @Description: 新增、编辑提供税额弹框组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\taxModal.vue
 * @LastEditTime: 2024-12-09 15:11:39
-->
<template>
  <el-dialog :title="isEdit ? '编辑' : '添加'" :visible.sync="visible" width="848px" class="tax-modal" :close-on-click-modal="false" append-to-body @close="handleClose">
    <el-divider></el-divider>
    <el-form :model="form" :rules="rules" ref="form" label-width="113px" class="mt-20">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="开票金额" prop="price">
            <el-input v-model="form.price" placeholder="请输入" style="width: 240px"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税率" prop="tax_rate">
            <el-input v-model="form.tax_rate" placeholder="请输入" style="width: 240px; position: relative"> </el-input>
            <span class="unit">单位：%</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="开票时间" prop="invoicing_time">
            <date-picker v-model="form.invoicing_time" :defaultValue="defaultValue" @changeDate="changeDate"></date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="税额" prop="tax_price">
            <el-input v-model="form.tax_price" placeholder="请输入" style="width: 240px" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item :label="isEdit ? '发票原件' : '附件'">
            <new-upload-file-more ref="uploadsdom" :close="close" @fileurl="getUrl" :list="fileList" :limit="1" @fileDel="delFile"> </new-upload-file-more>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-divider></el-divider>

    <div slot="footer" class="dialog-footer">
      <el-button class="cancel-btn" @click="handleClose">取消</el-button>
      <el-button class="submit-btn" type="primary" @click="handleSubmit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { isValidNumber } from '@/project/utils/index';

export default {
  name: 'taxModal',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    formdata: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const validatePrice = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入'));
      } else if (!isValidNumber(value, 2)) {
        callback(new Error('请输入正确的金额，最多保留2位小数'));
      } else {
        if (this.form.tax_rate) {
          this.form.tax_price = ((parseFloat(value) * parseFloat(this.form.tax_rate)) / 100).toFixed(2);
          this.$forceUpdate();
        }
        callback();
      }
    };

    const validateRate = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入'));
      } else {
        if (!isValidNumber(value, 2)) {
          callback(new Error('请输入正确的税率，最多保留两位小数'));
        } else if (value < 0 || value > 100) {
          callback(new Error('税率必须在0到100之间'));
        } else {
          this.form.tax_price = this.form.price ? ((parseFloat(this.form.price) * parseFloat(value)) / 100).toFixed(2) : 0;
          this.$forceUpdate();
          callback();
        }
      }
    };

    return {
      visible: true,
      form: {
        price: '',
        invoicing_src: '',
        invoicing_time: '',
        tax_rate: '',
        tax_price: '',
      },
      defaultValue: '',
      rules: {
        price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
        tax_rate: [{ required: true, validator: validateRate, trigger: 'blur' }],
        invoicing_time: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      close: false,
      fileList: [],
      handleSubmitFn: this.$throttle(this.handleSubmit),
    };
  },
  watch: {
    formdata: {
      handler(val) {
        if (this.isEdit && val) {
          this.form = { ...val };
          this.$set(this.form, 'invoicing_time', val.Invoicing_time);
          if (!val.Invoicing_src.length) return;
          const arr = val.Invoicing_src.split(',');
          this.fileList = arr.map((item) => {
            let parts = item.split('/');
            let filename = parts[parts.length - 1];
            return {
              name: filename,
              url: item,
            };
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //  日期选择器改变事件
    changeDate(val) {
      this.defaultValue = '';
      this.$set(this.form, 'invoicing_time', val);
    },
    // 关闭弹框
    handleClose() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
    // 删除文件
    delFile(item) {
      this.fileList.splice(item.index, 1);
    },
    // 获取文件url
    getUrl(e) {
      console.log('--------e', e);
      this.fileList.push({
        name: e.title,
        url: e.res,
      });
    },
    // 提交表单
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = {
            ...this.form,
            invoicing_src: this.fileList.map((file) => file.url).join(','),
          };
          if (this.isEdit) {
            delete data.Invoicing_src;
            delete data.Invoicing_time;
          }
          console.log('this.fileList', this.fileList);
          console.log('data', data);
          console.log('data', JSON.stringify(data));
          this.$emit('submit', data);
          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.tax-modal {
  :deep(.el-dialog) {
    border-radius: 8px;
    .el-dialog__title {
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
      color: #333;
    }
    .el-dialog__body {
      padding: 0 20px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
  }

  :deep(.el-upload) {
    .el-upload--picture-card {
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      cursor: pointer;
      line-height: 100px;
      vertical-align: middle;
      border: none;
    }
    .el-upload--picture-card i {
      font-size: 34px;
      font-weight: 800;
      color: #8c939d;
    }
  }

  .el-divider--horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }

  .unit {
    display: inline-block;
    line-height: 37px;
    min-width: 37px;
    text-align: center;
    box-sizing: border-box;
    line-height: 37px;
    position: absolute;
    right: 50px;
    bottom: 1px;
    top: 1px;
    color: rgba(153, 153, 153, 0.6);
  }

  .dialog-footer {
    margin-right: 20px;
    text-align: right;
    .cancel-btn,
    .submit-btn {
      background-color: #1b70ff;
      width: 112px;
      height: 44px;
      border-radius: 4px;
      font-size: 20px;
      color: #fff;
    }
    .cancel-btn {
      background-color: #fff;
      border: 1px solid #999999;
      color: #999999;
    }
  }

  :deep(.el-date-picker) {
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;

      &:hover,
      &:focus {
        border-color: #1b70ff;
      }
    }
  }
}
</style>
