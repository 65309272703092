import { render, staticRenderFns } from "./newCustomerService.vue?vue&type=template&id=c5dd9de8&scoped=true"
import script from "./newCustomerService.vue?vue&type=script&lang=js"
export * from "./newCustomerService.vue?vue&type=script&lang=js"
import style0 from "./newCustomerService.vue?vue&type=style&index=0&id=c5dd9de8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5dd9de8",
  null
  
)

export default component.exports