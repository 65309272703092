<!--
 * @Description: newComHead组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\newComHead.vue
 * @LastEditTime: 2024-12-27 13:32:32
-->
<template>
  <div class="head flex" id="chead">
    <div class="bread">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="custom-breadcrumb">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>{{ bread1 }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ bread2 }}</el-breadcrumb-item>
        <!-- <el-breadcrumb-item v-for="item in arr" :key="item">{{item}}</el-breadcrumb-item> -->
        <!-- <el-breadcrumb-item>{{info2}}</el-breadcrumb-item> -->
      </el-breadcrumb>
      <div class="back" v-show="showBack" @click="back">
        <i class="el-icon-arrow-left"></i>
        返回
      </div>
    </div>
    <div class="right flex">
      <el-button plai class="get-index" @click="getIndex">回到首页</el-button>

      <span v-if="info?.invitation_code" class="caaa dis" style="cursor: text">
        <img src="@/project/assets/qrcode.png" alt="邀请码" style="vertical-align: middle" />
        <span>邀请码：{{ info?.invitation_code }}</span>
        <!-- <span style="margin-left: 10px" v-show="codeTime">到期时间:{{ codeTime }}</span> -->
      </span>
      <!-- <span class="caaa dis" style="cursor: text" v-if="projectInfo?.invitation_code">
        <img src="@/project/assets/qrcode.png" alt="邀请码" />
        <span>邀请码</span>
        <span style="margin-left: 10px" v-show="codeTime">到期时间:{{ codeTime }}</span>
      </span> -->
      <!-- <template v-if="!$utils.disabled({ disabled: true }, true)">
        <el-badge :value="numberinfo.zixun" class="item" v-if="numberinfo.zixun > 0">
          <span class="caaa" @click="conChange">
            <img src="@/project/assets/chat.png" alt="在线咨询" style="height: 13px" />
            在线咨询
          </span>
        </el-badge>
        <span class="caaa" @click="conChange" v-else>
          <img src="@/project/assets/chat.png" alt="在线咨询" style="height: 13px" />
          在线咨询
        </span>
      </template> -->
      <!-- <span class="caaa dis">
                <i class="el-icon-s-check"></i>
                服务与咨询
            </span> -->
      <!-- <span class="caaa" @click="goPlat">
                <i class="el-icon-s-home"></i>
                平台管理
            </span> -->
      <div class="end flex">
        <img src="@/assets/company.png" />
        <div style="color: #6b6b6b; font-size: 10px; text-align: left">
          <div style="color: #333; font-size: 14px; font-weight: 800">
            <span>
              {{ info.user_name }}
            </span>
            <img class="arrow-down" src="@/project/assets/arrow-down.png" @click="exit" />
          </div>
          <span v-if="info.position_name">({{ info.position_name }})</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: JSON.parse(sessionStorage.getItem('userInfo')),
      bread1: sessionStorage.getItem('navs'),
      bread2: '',
      info2: '',
      arr: [],
      projectInfo: sessionStorage.getItem('projectInfo'),
      code: '',
      codeTime: '',
      numberinfo: {
        zixun: 0,
        kefu: 0,
      },
      timer2: null,
      projectData: '',
      isShow: false,
      showBack: false,
    };
  },
  watch: {
    $route(val) {
      let tree = JSON.parse(sessionStorage.getItem('forbread'));
      this.bread2 = this.filterNav(tree, val.path);
      this.arr = this.arr.reverse();
    },
  },
  beforeDestroy() {
    this.$bus.$off('endDayShow');
  },
  created() {
    this.$bus.$on('endDayShow', (res) => {
      console.log(res, 'resss');
      if (res.is_pay !== 1) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.projectData = res;
    });
    this.bread1 = sessionStorage.getItem('navs');
    this.init(JSON.parse(sessionStorage.getItem('userInfo')).type);
  },
  mounted() {
    let tree = JSON.parse(sessionStorage.getItem('forbread'));
    this.bread2 = this.filterNav(tree, this.$route.path);
    this.projectInfo = JSON.parse(sessionStorage.getItem('projectInfo'));
    this.getRule();

    this.$bus.$on('showBack', (res) => {
      this.showBack = res;
    });
    // console.log("返回数据", this.projectInfo)
  },
  methods: {
    activateEvent() {
      this.$bus.$emit('show', true);
    },
    getRule() {
      this.timer2 = setInterval(() => {
        this.$request.post('/collective/select/getRuleAll').then((res) => {
          let ruleArr = JSON.parse(sessionStorage.getItem('rule'));
          let ruleArr2 = res;
          let c = ruleArr.length == ruleArr2.length && ruleArr.sort().toString() === ruleArr2.sort().toString();
          if (!c) {
            sessionStorage.removeItem('userInfo');
            sessionStorage.removeItem('projectInfo');
            sessionStorage.removeItem('token');
            this.$router.push({ path: '/login' });
            this.$message.error('权限发生改变请重新登入');
          }
        });
      }, 60000);
    },
    filterNav(system, path, str) {
      // 筛选相应name
      this.arr = [];
      for (let item of system) {
        if (item.path == this.$route.fullPath) {
          str = item.name;
          // this.arr.push(str)
          return str;
        }
        if (item.children && item.children.length > 0) {
          let name = this.filterNav(item.children, this.$route.fullPath, str);
          if (name != '') {
            // this.arr.push(item.name)
            return name;
          }
        }
      }
      return '';
    },
    conChange() {
      let routeData = this.$router.resolve({ path: '/front/leaveMsg', query: { istype: '平台' } });
      window.open(routeData.href, '_blank');
    },
    goPlat() {
      let routeData = this.$router.resolve({ path: '/platMag/login' });
      window.open(routeData.href, '_blank');
      // this.$router.push({ path: '/platMag/login' })
    },
    // 跳转到登陆页
    getIndex() {
      this.$router.push({ path: '/' });
    },
    // 退出登录
    exit() {
      this.$confirm('确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sessionStorage.removeItem('userInfo');
          sessionStorage.removeItem('projectInfo');
          sessionStorage.removeItem('token');
          this.$router.push({ path: '/login' });
        })
        .catch(() => {});
    },
    init(type) {
      let login_type = JSON.parse(sessionStorage.getItem('userInfo')).login_type;
      if (login_type == 5) {
        this.bread1 = '项目总包';
      } else if (type == 2) {
        this.bread1 = '施工企业';
      } else if (type == 7) {
        this.bread1 = '班组长';
      } else if (type == 5) {
        this.bread1 = '劳务公司';
      } else if (type == 6) {
        this.bread1 = '咨询公司';
      } else if (type == 1) {
        this.bread1 = '工程项目';
      } else if ([3, 4].includes(type)) {
        this.bread1 = '供应商';
      } else if (type == 8) {
        this.bread1 = '专家';
      }
    },
    // 点击返回按钮
    back() {
      this.$bus.$emit('showBack', false);
      this.$bus.$emit('back');
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
};
</script>
<style scoped lang="less">
#chead {
  position: sticky;
  top: 0;
  z-index: 2;
}
.get-index {
  height: 38px;
  margin-right: 60px;
  transform: translateY(-8px);
  border-radius: 2px;
  color: #1b70ff;
  border-color: #1b70ff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #1b70ff;
  text-align: center;
  vertical-align: top;
}
.get-index.el-button {
  padding: 9px 15px !important;
}
.caaa {
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  color: #6b6b6b;

  img {
    margin-right: 5px;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    transform: translateY(-2px);
  }
}

.dis {
  margin: 0 20px;
}

.end {
  margin-top: -6px;
  margin-left: 40px;

  div {
    margin-right: 10px;
  }

  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
  }

  .arrow-down {
    margin-top: 4px;
    width: 12px;
    height: 12px;
  }
}

.right {
  margin-top: 15px;
}

.center {
  margin-top: 15px;
  .center_content {
    display: flex;
    align-items: center;
    width: 290px;
    height: 31px;
    background: rgba(255, 120, 89, 0.1);
    border-radius: 15px;
    padding: 0 0 0 10px;
    .left {
      width: 70%;
      font-size: 14px;
      color: rgba(255, 120, 89, 1);
    }
    .btn {
      width: 30%;
      height: 90%;
      background: rgba(255, 120, 89, 1);
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(255, 255, 255, 1);
    }
  }
}

.head {
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(31, 37, 51, 0.05);
  padding: 0px 20px;
  min-height: 52px;
  font-size: 13px;

  .bread {
    margin-top: 18px;
  }
  .back {
    color: #1b70ff;
    margin: 18px 0 8px;
    cursor: pointer;
  }
}

.flex {
  justify-content: space-between;
}

.number {
  // position: absolute;
  // right: 0;
  // top:2px;
  font-size: 12px;
  display: inline-block;
  width: 22px;
  height: 22px;
  // padding: 2px 5px;
  text-align: center;
  // line-height: 22px;
  background-color: #f00;
  color: #fff;
  border-radius: 50%;
}
</style>
<style lang="less">
.custom-breadcrumb {
  .el-breadcrumb__item {
    .el-breadcrumb__inner {
      color: #999999;
    }
  }
  .el-breadcrumb__item:last-child {
    .el-breadcrumb__inner {
      color: #0b2f59 !important;
    }
  }
}
</style>
