<!--
 * @Description: 下载进度弹框组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\downloadModal.vue
 * @LastEditTime: 2024-12-25 19:51:09
-->
<template>
  <el-dialog class="download-modal" :visible="dialogVisible" width="360px" top="15%" :show-close="false" append-to-body>
    <div class="modal-content">
      <p class="del-title">正在下载</p>
      <img src="@/project/assets/icon-download-progress.png" alt="" class="icon-download-progress" />
      <div class="mt-20" style="width: 312px">
        <el-progress :percentage="downloadProgress" color="rgba(27, 112, 255, 1)" define-back-color="rgba(230, 239, 255, 1)" :stroke-width="4" :show-text="false"></el-progress>
      </div>
      <p class="del-text">正在努力下载</p>
      <div class="button-group">
        <el-button class="confirm-btn" type="primary" @click="handleClose">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { downloadFiles } from '../utils';

export default {
  // 暂未使用
  name: 'downloadModal',
  props: ['file'],
  data() {
    return {
      dialogVisible: true,
      downloadProgress: 0,
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = true;
    },
    startDownload() {
      downloadFiles(this.file, '', (progress) => {
        this.downloadProgress = progress;
      });
    },
  },
  mounted() {
    this.startDownload();
  },
};
</script>

<style lang="less" scoped>
.download-modal {
  :deep(.el-dialog) {
    border-radius: 16px;
    background: url('@/project/assets/icon-download-bg.png') no-repeat center;
    background-size: cover;
    height: 203px;
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-download-progress {
    position: absolute;
    top: -62px;
    left: 50%;
    transform: translate(-20%, -50%);
    width: 200px;
    height: 140px;
  }

  .del-title {
    position: absolute;
    top: -56px;
    left: 6px;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 26.4px;
    color: rgba(27, 112, 255, 1);
    text-align: left;
    vertical-align: top;
  }

  .del-text {
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 15.84px;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
  }

  .button-group {
    display: flex;
  }

  .cancel-btn {
    font-size: 20px;
    width: 112px;
    border-radius: 4px;
  }

  .confirm-btn {
    width: 312px;
    height: 44px;
    opacity: 1;
    border-radius: 22px;
    background: rgba(27, 112, 255, 1);
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
}
</style>
