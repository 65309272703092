<!--
 * @Description: newCount2组件，用于展示税务统计
 * @Author: fal
 * @Date: 2024-11-25 15:30:20
 * @FilePath: \pc.ui\src\project\components\newCount2.vue
 * @LastEditTime: 2024-12-27 15:06:16
-->
<template>
  <div class="flex" id="count">
    <div v-for="(item, index) in Object.keys(list)" :key="index" class="list" :style="{ display: labels[index]?.label == undefined ? 'none' : '' }">
      <span class="bl">{{ labels[index]?.label }}</span>
      <div class="num">
        <span>{{ getNumber(list[item], labels[index]?.label) }} </span>
        <img class="icon" :src="iconMap[labels[index]?.icon]" alt="" />
      </div>
    </div>
    <slot name="slot"></slot>
  </div>
</template>
<script>
export default {
  name: 'newcount2',
  data() {
    return {
      iconMap: {
        1: require('@/project/assets/icon-count-g1.png'),
        2: require('@/project/assets/icon-count-g2.png'),
        3: require('@/project/assets/icon-count-y1.png'),
        4: require('@/project/assets/icon-count-y2.png'),
        5: require('@/project/assets/icon-count-y3.png'),
      },
    };
  },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    // this.getNumber()
  },
  methods: {
    getNumber(data, title) {
      console.log(data, title);

      if ((data + '').indexOf('.') != -1) {
        const money = data + '';
        const temp = money.split('.');

        let val = temp[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + (temp[1].length >= 2 ? temp[1] : temp[1] + '0' || '00');
        return val;
      }
      if (title.indexOf('元') != -1) {
        return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + '00';
      }
      return (data + '').replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '.' + '00';
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}

.list {
  box-sizing: border-box;
  min-width: 19.35rem;
  height: 5.5rem;
  margin-right: 0.62rem;
  margin-bottom: 0.62rem;
  padding: 0.75rem;
  opacity: 1;
  border-radius: 0.25rem;
  background: rgba(230, 239, 255, 0.3);
  .bl {
    margin-top: 0.625rem;
    display: block;
    height: 1rem;
    opacity: 1;
    /** 文本1 */
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0rem;
    line-height: 0.99rem;
    color: rgba(107, 107, 107, 1);
    text-align: left;
    vertical-align: top;
  }
  .num {
    margin-top: 0.3125rem;
    display: flex;
    align-items: end;
    opacity: 1;
    font-size: 1.25rem;
    font-weight: 800;
    letter-spacing: 0rem;
    color: rgba(11, 47, 89, 1);
    text-align: left;
    .icon {
      margin-left: auto;
      width: 32px;
      height: 32px;
    }
  }
}
</style>
