<!--
 * @Description: tree组件
 * @Author: fal
 * @Date: 2024-11-26 17:12:42
 * @FilePath: \pc.ui\src\project\components\tree\tree.vue
 * @LastEditTime: 2025-01-03 10:10:24
-->
<template>
  <div class="my-tree">
    <tree-node ref="treeNode" v-for="node in data" :key="node.id" :node="node" :edit="edit" :select_id="select_id" v-bind="$attrs" v-on="$listeners"></tree-node>
  </div>
</template>

<script>
import TreeNode from './treeNode.vue';
export default {
  name: 'MyTree',
  components: {
    TreeNode,
  },
  props: {
    data: Array,
    edit: Boolean,
    select_id: String | Number,
  },
  methods: {},
};
</script>
<style scoped>
.my-tree {
  overflow: hidden;
}
</style>
