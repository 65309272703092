<template>
<div class="fix-header">
    <div class="head_top">
        <div class="mains">
            <div class="left">科建项管工程信息网管理系统，用于帮助企业、个人管理自己的项目</div>
            <div class="right">
                <!-- <img src="@/assets/header/weibo.png" alt="" srcset="">
                <img src="@/assets/header/qq.png" alt="" srcset="">
                <img src="@/assets/header/pengyouquan.png" alt="" srcset="">
                <img src="@/assets/header/weixin.png" alt="" srcset="">
                <img src="@/assets/header/fenxiang.png" alt="" srcset=""> -->
                <span>|</span> <el-link href="https://www.sckjxg.com/" target="_blank"  :underline="false">企业首页</el-link>
                <span>|</span><a @click="go('/front/newPage/info/operate')">操作手册</a>
                <span>|</span><a @click="go('/front/service')">意见箱</a>
                <span>|</span>
                <!-- <a>注册</a>
                <span>|</span><a>当前主体名称</a>
                <span>|</span><a>退出登录</a> -->
            </div>
        </div>
    </div>
    <div class="header">
        <div class="nav  navfree" :class="{navfree2: $route.name == 'frontintro'||$route.name == 'frontIndex'}">
            <div class="nav2">
                <img src="@/assets/logo8.png" v-if=" $route.name == 'frontintro'||$route.name == 'frontIndex'" />
                <img src="@/assets/logo7.png" v-else />
                <!-- <span class="iconfont xm_shixiang"></span> -->
                <span v-for="(item, index) in nav" :class="{active: curIndex === index,active2:$route.name == 'frontintro'||$route.name == 'frontIndex'}" :key="index"
                    @click="goNav(item, index)">{{item.name}}</span>
                <span v-if="!user.id">
                    <a @click="goLogin" class="btn">登录</a>
                    <a @click="goregister" style='margin-left:10px' class="btn">注册</a>
                </span>

                <span v-else>
                    <a class="btn" @click="goBackstage">进入系统</a>
                    <a class="btn" @click="EiteUser" style="margin-left:5px;opacity: .7;">退出</a>
                </span>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import {
        consult,
        consultOther,
        serviceMagforConsult,
        labor,
        personMag,
        platMag,
        project,
        allMag,
        supplier,
        doer,
    } from "@/components/data.js";
    const urlArr = [
        '/front/hire',
        '/front/material',
        '/front/machine',
        '/front/business',
        '/front/about'
    ]
    export default {
        data() {
            return {
                nav: [
                    { path: '/', name: '数字化管理平台' },
                    // { path: '/front/hire', name: '工程招聘', disabled: true },
                    // { path: '/front/material', name: '材料设备', disabled: true },
                    // { path: '/front/machine', name: '工程机械', disabled: true }, 
                    // { path: '/front/business', name: '业务大厅', disabled: true },
                    ////////////////////////////////////////////////////////////////////////

                    { path: '/front/hire?type=recruit', name: '工程招聘', disabled: true },
                     { path: '/front/material?type=material', name: '材料设备', disabled: true },
                    { path: '/front/machine?type=machinery', name: '工程机械', disabled: true }, 
                    { path: '/front/business?type=comprehensive', name: '业务大厅', disabled: true },
                    // { path: '/front/newPage/info/newrecHire', name: '招聘详情', disabled: true }, 
                    // { path: '/front/newPage/info/newhunHire', name: '求职详情' },
                    // { path: '/front/info/newlabor', name: '劳务详情' },
                    // { path: '/front/newPage/info/newsupplyDetails', name: '供应详情', disabled: true },
                    // { path: '/front/newPage/info/newsupplyDetails', name: '供应详情', disabled: true },
                    // { path: '/front/newPage/info/newconDetail', name: '公司详情', disabled: true },
                    // { path: '/front/newPage/info/newperDetal', name: '专家详情', disabled: true },
                    // { path: '/front/newPage/info/operate', name: '操作手册', disabled: true },
                    // { path: '/front/newPage/info/train', name: '线下培训', disabled: true },

                    /////////////////////////////////////////////////////////////////////////////
                    // { path: '/front/service', name: '三方咨询', disabled: true }, 
                    { path: '/front/about', name: '关于我们' }
                ],
                // active: -1,
                user: ''
            }
        },
        computed: {
            curIndex() {
                const cur = urlArr.findIndex(item => this.$route.fullPath.includes(item));
                return cur + 1;
            }
        },
        mounted() {
            // { path: '/front/hire', name: '工程招聘' },
            this.user = ''
            this.user = JSON.parse(sessionStorage.getItem('userInfo')) || ''
            // { path: '/', name: '首页' },
        },
        methods: {
            goLogin() {
                this.$router.push({ path: '/login' })
            },
            goregister() {
                this.$router.push({ path: '/sign' })
            },
            EiteUser() {
                sessionStorage.removeItem('projectInfo')
                sessionStorage.removeItem('token')
                sessionStorage.removeItem("tokenOrder");
                sessionStorage.removeItem('userInfo')
                this.user = ''
            },
            async goBackstage() {

                if (!sessionStorage.getItem('token')) {
                    this.$router.push({ path: '/login' });
                    return
                }
                let forbread = JSON.parse(sessionStorage.getItem('forbread'));
                // let nav = sessionStorage.getItem("navs") || '';
                // let menuList = await this.getMenu();

                // if (nav.indexOf("项目总包") > -1) {
                //     this.navs = allMag;
                // } else if (nav.indexOf("施工") > -1) {
                //     this.navs = doer;
                // } else if (nav.indexOf("劳务公司") > -1) {
                //     this.navs = labor;
                // } else if (nav.indexOf("咨询公司") > -1) {
                //     this.navs = consult;
                // } else if (nav.indexOf("其他咨询公司") > -1) {
                //     this.navs = consultOther;
                // } else if (nav.indexOf("项目") > -1) {
                //     this.navs = menuList;
                // } else if (nav.indexOf("供应商") > -1) {
                //     this.navs = supplier;
                // } else if (nav.indexOf("个人") > -1) {
                //     this.navs = personMag;
                // } else if (nav.indexOf("平台") > -1) {
                //     this.navs = platMag;
                // } else {
                //     this.$router.push({ path: '/login' });
                //     return
                // }
                sessionStorage.setItem("changeNav", null);
                sessionStorage.setItem("forbread", JSON.stringify(forbread));
                // let path = this.findFirstPath(this.navs)
                this.$router.push({ path: forbread[0].path });
            },
            findFirstPath(consultOther) {
                for (let i = 0; i < consultOther.length; i++) {
                    const item = consultOther[i];

                    if (item.children.length > 0) {
                        const path = this.findFirstPath(item.children);
                        if (path) { return path; }
                    } else {
                        return item.path;
                    }
                } return null;
            },
            getMenu() {
                let nav = sessionStorage.getItem("navs");
                let type = ''
                if (nav.indexOf("项目总包") > -1) {
                    type = 1;
                } else if (nav.indexOf("施工") > -1) {
                    type = 2
                } else if (nav.indexOf("劳务公司") > -1) {
                    type = 4
                } else if (nav.indexOf("咨询公司") > -1) {
                    type = 5
                } else if (nav.indexOf("其他咨询公司") > -1) {
                    type = 6
                } else if (nav.indexOf("项目") > -1) {
                    type = 0
                } else if (nav.indexOf("供应商") > -1) {
                    type = 3
                } else if (nav.indexOf("个人") > -1) {
                    type = 7
                } else if (nav.indexOf("平台") > -1) {
                    type = 8
                }
                return new Promise((resolve, reject) => {
                    this.$http({
                        url: "/api/admin.project.survey/getMenu",
                        data: {
                            user_type: type
                        },
                        done: (item) => {
                            let newMenu = []
                            newMenu = this.setChecked(item)
                            resolve(newMenu);
                        },
                    });
                });
            },
            setChecked(tree) {
                tree.forEach(item => {
                    item.name = item.menu_name;
                    item.path = item.menu_url;
                    // this.$forceUpdate()
                    if (item.children) {
                        this.setChecked(item.children);
                    }
                });
                return tree;
            },
            goSign() {
                this.$router.push({ path: '/sign' })
            },
            goNav(item, index) {
                console.log(item,index);
                if (this.$utils.disabled(item)) return
                // this.active = index
                this.$router.push({ path: item.path })
                this.goTop();
            },
            goTop() {
                const app = document.getElementsByClassName('login')[0];
                app.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            },
            go(url){
                this.$router.push({
                    path:url
                })
            }
        },
        created() {
            this.$bus.$on('gos',this.goNav)
            // console.log(this.user,'777')
        },
        destroyed() {
            // this.EiteUser()
        },
        beforeDestroy(){
            this.$bus.$off('gos')
        }

    }
</script>
<style lang="less" scoped>
    .fix-header {
        position: sticky;
        top: 0;
        z-index: 3;
    }
    .header {
        // min-width: 68.75rem;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1999;
        // height: 5rem;
    }

    .nav {
        display: flex;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0rem .125rem .625rem 0rem rgba(0, 0, 0, 0.08);
        padding: 0.5rem;
        font-size: .9375rem;
        margin: auto;

        img {
            width: 8.625rem;
            // height: 2.8125rem;
            margin-right: 4.5rem;
        }
    }

    .nav span {
        display: inline-block;
        margin-right: 3.6875rem;
        color: #262626;
        line-height: 2.1875rem;
        font-family: Source Han Sans CN;
        cursor: pointer;
        font-size: 16px;
    }

    .userClass {
        color: #4E90FF !important;
        padding-left: .625rem;
        position: relative;
        top: .0625rem;
    }

    .nav .active {
        color: rgba(27, 112, 255, 1);
    }

    .active2{
        color: #ffffff !important;
    }

    .nav .btn {
        display: inline-block;
        background: linear-gradient(75deg, #5192FF 0%, #1B70FF 100%);
        color: white;
        font-size: .75rem;
        border-radius: .625rem;
        line-height: 1.875rem;
        height: 1.875rem;
        padding: 0 .625rem;
        margin-top: .125rem;
    }

    .navfree {
        box-shadow: 0rem .1875rem .75rem 0rem rgb(0 0 0 / 5%);
        background: rgba(255, 255, 255, 0.7);
    }

    .navfree2 {
        background: rgba(255, 255, 255, 0.15);
        box-shadow: 0rem .1875rem .75rem 0rem rgba(0, 0, 0, 0.05);

        span {
            color: white;
            font-size: 15px;
        }
    }

    .nav2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
    }


    .head_top{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 30;
        box-shadow: 0rem 0.1875rem 0.75rem 0rem rgba(0, 0, 0, 0.05);
        background:rgb(221, 221, 221);
        .mains{
            width: 80%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666666;
            .left{
                font-size: 14px;
                width: 40%;
            }
            .right{
                width: 60%;
                font-size: 14px;
                cursor: pointer;
                text-align: right;
                a{
                    text-decoration: none;
                    color: #666666;
                }
                img{
                    width: 15px;
                    // height: 20px;
                    margin-left: 15px;
                    cursor: pointer;
                }
                span{
                    margin: 0 15px;
                }
            }
        }
    }
</style>