<!--
 * @Description: progress自定义进度条组件
 * @Author: fal
 * @Date: 2024-11-21 18:31:27
 * @FilePath: \pc.ui\src\project\components\progressLine.vue
 * @LastEditTime: 2024-12-17 17:54:04
-->
<template>
  <div class="progress-line">
    <el-progress :stroke-width="9" :color="getProgressColor(num)" :percentage="num" :show-text="false"> </el-progress>
    <span class="progress-text" :style="{ color: getProgressColor(num) }">{{ num }}%</span>
  </div>
</template>

<script>
export default {
  props: ['percent'],
  data() {
    return {
      num: 0,
    };
  },
  watch: {
    percent: {
      handler(newVal) {
        let number = parseFloat(newVal);
        this.num = parseFloat(number.toFixed(2));
        console.log('this.num', this.num);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getProgressColor(process) {
      if (process == 100) {
        return '#1CA4FF';
      } else if (process > 0 && process < 100) {
        return '#37CCA7';
      } else if (process > 100) {
        return '#FFB70F';
      } else if (process < 0) {
        return '#ff7c5f';
      }
      return '';
    },
  },
};
</script>

<style lang="less" scoped>
.progress-line {
  display: flex;
  align-items: center;
  .el-progress {
    width: 80px;
  }
  .progress-text {
    margin-left: 10px;
  }
}
</style>
