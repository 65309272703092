<template>
  <div class="menu">
    <div class="fix">
      <div class="title" style="padding: 10px">
        <img src="@/assets/logo8.png" style="width: 200px; height: 65px" />
      </div>
      <el-menu
        class="el-menu-vertical-demo"
        background-color="#1F2533"
        text-color="#888F97"
        active-text-color="#fff"
        :default-active="$route.path"
        router
        unique-opened
        @open="handleOpen"
        @close="handleClose"
      >
        <template v-for="item in navs">
          <el-menu-item v-if="!item.children || item.children.length === 0" :key="item.path" :index="item.path">
            <i :class="'iconfont ' + item.icon" v-if="item.icon"></i>
            <i class="iconfont moren" v-else></i>
            <!-- <img class="imga" v-if="item.imga" :src="require('../assets' + item.imga)" />
                        <img class="imgg" v-if="item.imga" :src="require('../assets' + item.imgg)" /> -->
            <!-- <i class="el-icon-menu"></i> -->
            <el-badge :value="numberinfo.kefu" v-if="item.name == '我的顾问' && numberinfo.kefu > 0" class="item">
              <span>{{ item.name }}</span>
            </el-badge>
            <span v-else>{{ item.name }}</span>
            <!-- <span slot="title">{{ item.name }}</span> -->
          </el-menu-item>
          <el-submenu v-if="item.children && item.children.length > 0" :key="item.path" :index="item.path">
            <template slot="title">
              <i :class="'iconfont ' + item.icon" v-if="item.icon" style="font-size: 24px"></i>
              <i class="iconfont moren" v-else></i>
              <!-- <img class="imga" v-if="item.imga" :src="require('../assets' + item.imga)" />
                            <img class="imgg" v-if="item.imgg" :src="require('../assets' + item.imgg)" /> -->

              <span>{{ item.name }}</span>
              <span class="badge" v-if="item.badge">{{ item.badge }}</span>
            </template>
            <div v-if="item.children && item.children.length > 0">
              <template v-for="(subItem, subIndex) in item.children">
                <el-menu-item-group v-if="subItem.children.length == 0" :key="subItem.path">
                  <el-menu-item :index="subItem.path">
                    <span>{{ subItem.name }}</span>
                    <span class="badge sub" v-if="subItem.badge">{{ subItem.badge }}</span>
                  </el-menu-item>
                </el-menu-item-group>
                <el-submenu :index="subIndex.toString()" v-else :key="subIndex">
                  <template slot="title">
                    <span slot="title">{{ subItem.name }}</span>
                  </template>
                  <el-menu-item-group :key="subIndex" v-if="subItem.children.length > 0">
                    <el-menu-item v-for="(ssubMenu, ssIndex) in subItem.children" :key="ssIndex" :index="ssubMenu.path">
                      <span>{{ ssubMenu.name }}</span>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </template>
            </div>
          </el-submenu>
        </template>
      </el-menu>
    </div>
  </div>
</template>
<script>
import { consult, consultOther, serviceMagforConsult, labor, personMag, platMag, project, allMag, supplier, banzuzhang, doer, project2 } from './data.js';
export default {
  data() {
    return {
      navs: [],
      consultServiceNavs: serviceMagforConsult,
      connectProject: sessionStorage.getItem('changeNav'),
      menuList: [],
      numberinfo: {
        zixun: 0,
        kefu: 0,
      },
    };
  },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    nav(val) {
      console.log('-----val', val);
    },
  },
  computed: {
    isswitch() {
      return this.$store.state.consult.connectProject;
    },
  },
  watch: {
    isswitch(val) {
      if (val.allMagjump) {
        this.navs = this.menuList;
      } else if (val.consultjump) {
        this.navs = serviceMagforConsult;
      }
      console.log('控制菜单4444', this.navs);
      // sessionStorage.setItem("forbread", JSON.stringify(this.navs));
    },
    $route(to, from) {},
  },
  created() {
    this.$bus.$on('numberChange', (res) => {
      this.numberinfo = res;
    });
  },
  mounted() {
    this.init();
    // this.$router.push({path: this.navs[0].path})
    this.$bus.$on('setBadge', () => {
      this.setBadge(this.navs);
    });
  },
  methods: {
    async init() {
      this.menuList = await this.getMenu();
      this.navs = this.menuList;
      sessionStorage.setItem('forbread', JSON.stringify(this.navs));
      const notJump = sessionStorage.getItem('notJump');
      if (notJump) {
        // 不做任何处理
      } else if (this.navs[0].children.length > 0) {
        this.$router.push({
          path: this.navs[0].children[0].path,
        });
      } else {
        this.$router.push({
          path: this.navs[0].path,
        });
      }

      if (notJump) sessionStorage.removeItem('notJump');
    },
    getMenu() {
      let nav = sessionStorage.getItem('navs');
      let register_type = JSON.parse(sessionStorage.getItem('userInfo')).register_type;
      let type = '';
      let name = '';
      let obj = {};
      if (nav.indexOf('项目总包') > -1) {
        type = 1;
      } else if (nav.indexOf('施工') > -1 && nav != '施工项目') {
        type = 2;
      } else if (nav.indexOf('劳务公司') > -1) {
        if (register_type == 2) {
          type = 10;
          obj.name = '班组长';
        } else {
          type = 4;
        }
      } else if (nav.indexOf('其他咨询公司') > -1) {
        type = 6;
      } else if (nav.indexOf('咨询公司') > -1) {
        type = 5;
      } else if (nav.indexOf('项目') > -1 && nav != '咨询项目' && nav != '施工项目') {
        type = 0;
      } else if (nav.indexOf('供应商') > -1) {
        type = 3;
      } else if (nav.indexOf('个人') > -1) {
        type = 7;
      } else if (nav.indexOf('平台') > -1) {
        type = 8;
      } else if (nav == '咨询项目') {
        type = 9;
      } else if (nav == '班组长') {
        type = 10;
        obj.name = '班组长';
      } else if (nav == '施工项目') {
        type = 11;
      }
      let isOperation = sessionStorage.getItem('isOperation');
      obj.user_type = type;
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/api/admin.project.survey/getMenu',
          data: {
            ...obj,
          },
          done: (item) => {
            let newMenu = [];
            if (isOperation == 2) {
              newMenu = project2;
            } else {
              newMenu = this.setChecked(item);
            }
            if (this.$utils.disabled({ disabled: true }, true)) {
              newMenu = newMenu.filter((item) => !['信息管理', '我的顾问', '我的咨询', '我的求职'].includes(item.menu_name));
            }
            // 过滤日志
            newMenu = newMenu.filter((item) => item.id != 3085);
            this.setMaterialMenu(newMenu);
            this.setBadge(newMenu);
            resolve(newMenu);
          },
        });
      });
    },
    // 单独处理材料供应商的menu
    setMaterialMenu(data) {
      const { type } = JSON.parse(sessionStorage.getItem('userInfo'));
      if (type === 3) {
        data.forEach((item) => {
          if (item.path == '/supplier/index') item.path = '/supplier/newIndex';

          if (item.path == '/supplier/engineeringProject') item.path = '/supplier/newProject';
        });
      }
    },
    setBadge(data) {
      const map = {
        进度预警: 'jdyj',
        造价预警: 'zjyj',
        人员预警: 'ryyj',
        材料预警: 'clyj',
        机械预警: 'jxyj',
      };
      // 工程预警
      const cur = data.find((item) => item.menu_name == '工程预警');
      const { project_id } = JSON.parse(sessionStorage.getItem('userInfo'));
      if (cur && project_id) {
        this.$http({
          url: '/api/admin.material.machinery/earlyBubble',
          data: {
            project_id,
            type: 0,
          },
          done: (res) => {
            let total = 0;
            (cur.children || []).forEach((item) => {
              item.badge = res[map[item.menu_name]];
              total += item.badge;
            });
            cur.badge = total;
          },
        });
      }
    },
    setChecked(tree) {
      tree.forEach((item) => {
        item.name = item.menu_name;
        item.path = item.menu_url;
        // this.$forceUpdate()
        if (item.children) {
          this.setChecked(item.children);
        }
      });
      return tree;
    },
    handleOpen(e) {
      console.log(e);
    },
    handleClose(e) {
      console.log(e);
    },
  },
  beforeDestroy() {
    this.$bus.$off('setBadge');
  },
};
</script>
<style lang="less" scoped>
.el-menu-item i,
.el-submenu i {
  margin-right: 10px;
}

.item {
  /deep/.el-badge__content.is-fixed {
    top: 24px !important;
    right: -5px !important;
  }
}

.title {
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 2px;
}

.fix {
  position: fixed;
  z-index: 99;
  background: #1f2533;
}
.el-menu-item.is-active {
  border-left: 3px solid #409eff;
  background-color: #282c35 !important;
  .imga {
    display: inline-block;
  }
  .imgg {
    display: none;
  }
}
.el-menu-item,
.el-submenu {
  border-left: 3px solid transparent;
  .imga {
    display: none;
  }
  img {
    width: 18px;
    margin-right: 10px;
  }
}
.el-submenu__title,
.el-menu-item {
  height: 50px;
  line-height: 50px;
}
.el-submenu {
  .badge {
    float: right;
    margin-right: 24px;
    &.sub {
      margin-right: 0;
    }
  }
}
</style>
