import { Loading } from 'element-ui';
/* eslint-disable*/
import axios from "axios";
// import md5 from 'js-md5'
import md5 from "js-md5";
import Vue from "vue";
// import { encryptByAES } from "./crypto.js";
import { apiURL } from '../config/index.js'
import store from "@/store";
// import AES from "@/util/AES.js";
axios.defaults.withCredentials = true;
let isAlertShown = false;
const alertStatus = Vue.prototype.$alertStatus;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

let isProp = true; // 是否是生产环境
let isOwnProp = false; // 单独的接口需要测试环境

// let propURL = "https://api.kejiankeji.com/";
// let propURL = "https://kjapi.ehongzhuo.com.cn/";
// let propURL = "http://192.168.5.196:1028";
let propURL = apiURL;

function loginout(vue) {
  // window.location.href='http://47.108.71.224:8040/index.html'
  vue.$router.push({
    path: "/",
  });
  // vue.$Message.info('登录超时,请重新登录')
}
// if (sessionStorage.getItem('token') == null) {
//sessionStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZXhwIjoxNjcwOTg0NTUwfQ.8VFeC1NCK7LMIQ9SOKCuJSeRpVFD9Y4XiO_oVciA1WQ')
// }

let GetData = (opt, vue) => {
  const disableUrlArr = ['api/admin.supply.consult/getMessageNum', '/api/admin.project.manage/getRuleAll']
  let loadingInstance = null;
  // if (!disableUrlArr.includes(opt.url)) {
  //   loadingInstance = Loading.service({
  //     background: 'rgba(0, 0, 0, 0.5)',
  //     text: '加载中...'
  //   });
  // }
  // let r = random();       //签名 - 不能是全局的，需要是局部的 ，会导致请求超时
  // let rr = time();
  // let rrr = GetSign(r,rr);
  // let BaseUrl = isProp ? propURL : testURL;
  // let baseURL = isOwnProp ? opt.baseURL : propURL;

  // console.log(axios.interceptors.response)
  let gg = "";
  if (
    opt.url == "/eb/project/report/downloadmodel" ||
    opt.url.indexOf("downloadAttachment") > -1
  ) {
    gg = "blob";
  }
  for (let i in opt.data) {
    // console.log(i)
    // opt.data[i] = encryptByAES(opt.data[i])
  }
  if (opt.add) {
    // 项目
    // let loca = sessionStorage.getItem("projectId");
    // let se = sessionStorage.getItem("projectId");
    // if (loca && se) {
    //   opt.data.project_id = loca == se ? loca : se;
    // }
    opt.data.project_id = sessionStorage.getItem("projectId");
    // opt.data.project_id = 24
  }
  if (opt.addc) {
    // 企业
    opt.data.prise_id = JSON.parse(sessionStorage.getItem("userInfo")).prise_id;
  }
  if (opt.addp) {
    // 个人
    opt.data.user_id = JSON.parse(sessionStorage.getItem("userInfo")).id;
  }
  let token = "";
  let _isNoProject = false;
  if (sessionStorage.getItem("tokenOrder")) {
    _isNoProject = true;
    token = sessionStorage.getItem("tokenOrder");
  } else {
    token = sessionStorage.getItem("token");
  }
  // let time1 = Date.parse(new Date());
  const time1 = Date.parse(new Date()) / 1000; // 第一种，推荐
  // const time1 = new Date().getTime() // 第二种，推荐
  // const time1 = Date.parse(new Date()) // 第三种，不推荐，精度差一些
  axios({
    url: opt.url,
    // baseURL: baseURL || BaseUrl,
    baseURL: apiURL,
    method: opt.type || "post",
    // data: encryptByAES(JSON.stringify(opt.data)),
    data: opt.data,
    params: opt.params,
    responseType: opt.responseType || "",
    //  responseType:opt.responseType || "json",
    headers: {
      //'Content-Type': 'application/x-www-form-urlencoded',
      "x-requested-with": "XMLHttpRequest",
      token: token,
      access_token: md5(time1 + "yLJNQ27gBPQozakkjqbnLqTTY3KfOsEpfRn4ePCC5"),
      timestamp: time1,
      urladdress: vue.$route.path,
      isNoProject: _isNoProject,
    },

    validateStatus(status) {
      return status >= 200 && status < 300; // default
    },
  })
    .then((response) => {
      loadingInstance && loadingInstance.close();
      if (response.data.msg == "请登录") {
        // Vue.prototype.$alertStatus = true
        vue.$router.push({
          path: "/",
        });
      }
      if (response.data.code == -2) {
        // Vue.prototype.$alertStatus = true
        vue.$router.push({
          path: "/project/brife",
          query: {
            brifeType: 1,
          },
        });
      }
      if (response.data.code == -3) {
        // Vue.prototype.$alertStatus = true
        // vue.$router.push({
        //   path: "/project/brife",
        //   query: {
        //     brifeType: 1,
        //   },
        // });
        vue.$router.push({ path: "/front/frontfee2" });
      }
      if (response.data.code == -4) {
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("projectInfo");
        sessionStorage.removeItem("tokenOrder");
        sessionStorage.removeItem("token");
        vue.$router.push({ path: "/login" });
        // vue.$message.error("账号在其他地方登录");
        // vue.$router.push({
        //   path: "/",
        // });
      }
      //console.log("返回数据", response.data);
      if (response.data.code == 2) {
        vue.$message.error("导入内容中又错误的值，请检查");
      }
      if (response.data.code === 0) {
        // console.log(response.data['Message']);
        opt.done && opt.done(response.data["data"]);
      } else {
        // if (response.data.code != -2) {
        //   vue.$message.error(response.data.msg);
        // }
        if (response.data.code == 1) {
          vue.$message.error(response.data.msg);
        }
        if (response.data.code == 2) {
          vue.$message.error("导入内容中又错误的值，请检查");
        }
        if(response.data.code == 5){
          vue.$router.push({
            path:'/front/frontfee2'
          })
        }
        opt.fail && opt.fail(response);

        if (Vue.prototype.$alertStatus) {
          Vue.prototype.$alertStatus = false;
        }

        // console.log(store.state.isAlertShown,9999)
      }

      // timeOut(vue)
      // if (opt.url == '/eb/file') {
      // } else {
      //     if (opt) {
      //         console.log(2)
      //         opt.done && opt.done(response['data']);
      //         if (response.data.status_code != 0) {
      //             // console.log(response)
      //             if (response.data.code == '505') {
      //                 console.log(2)
      //                 vue.$Message.info('登录超时,请重新登录')
      //                 vue.$router.push({ path: "/" });
      //             }
      //             // if(response.data.msg=='请登录'){
      //             //   vue.$Modal.info({
      //             //     title: '提示',
      //             //     content: '<p>请重新登录</p>',
      //             //     onOk: () => {
      //             //       setTimeout(() => {
      //             //         vue.$router.push({path:"/login"});
      //             //       }, 1000);
      //             //     },
      //             //     okText: '',
      //             //     cancelText: '取消'
      //             //   });
      //             // }
      //         }

      //     } else {
      //         // console.log(response)
      //         if (response.data['data']) {
      //             // console.log(response.data['Message']);
      //             opt.done && opt.done(response['data']);
      //         } else {
      //             opt.fail && opt.fail(response);
      //         }
      //     }
      // }
    })
    .catch(function (XMLHttpRequest, textStatus, errorThrown, error) {
      // console.log(XMLHttpRequest.response);
      // vue.$toast('操作失败，请稍后再试',5000)
      // console.log(response)
      if (XMLHttpRequest.response && XMLHttpRequest.response.status == 504) {
        if (opt.url.indexOf("login") == -1) {
          vue.$router.push({
            path: "/login",
          });
        }
      }
      opt.fail && opt.fail(XMLHttpRequest.response);
    });
};
//导出数据
var filePost = function (url, params, config) {
  let loadingInstance = null;
  loadingInstance = Loading.service({
    background: 'rgba(0, 0, 0, 0.5)',
    text: '正在导出中，请稍后'
  });
  if (params.add) {
    // 项目
    params.project_id = sessionStorage.getItem("projectId");
    // opt.data.project_id = 24
  }
  // let userAuth = JSON.parse(sessionStorage.getItem("userAuth"));
  return new Promise((resolve, reject) => {
    axios
      .create({
        baseURL: propURL,
        timeout: 0,
        headers: {
          "Content-Type": "application/json-patch+json",
          token: sessionStorage.getItem("token"),
        },
        // responseType: "arraybuffer" //必须设置，不然导出的文件无法打开
      })
      .post(url, params, config)
      .then((res) => {
        loadingInstance && loadingInstance.close();
        resolve(res);
      })
      .catch((err) => {
        loadingInstance && loadingInstance.close();
        reject(err);
      });
  });
};
//下载模板
var dowmTemplate = function (url, name,type) {
  
  const link = document.createElement("a");
  link.style.display = "none";
  //根据是否传染type,type为真就不需要加上propURL,否则需要加上propURL
  if(type){
  link.href =url;
  }else{
  link.href = propURL + url;
  }
  link.target = "_blank";
  link.download = name;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

var requsetFile = function (uri, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      // baseURL:baseurl,
      url: uri, //propURL +
      headers: {
        "Content-Type": "application/octet-stream;chartset=UTF-8",
        "Access-Control-Allow-Origin": "true",
      }, //定义相应头
      responseType: "blob", //定义
      data: params || {},
    })
      .then(function (response) {
        if (response.status === 200) {
          var headers = response.headers;
          // console.log(headers["file-name"]);
          resolve(response);
        } else {
          // Vue.prototype.$mymessage(response.data.msg, 'error');
        }
      })
      .catch(function (error) {
        // handErr(err, uri);
      });
  });
};

export { GetData, requsetFile, filePost, dowmTemplate };
