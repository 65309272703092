import { render, staticRenderFns } from "./tree.vue?vue&type=template&id=2b5699f7&scoped=true"
import script from "./tree.vue?vue&type=script&lang=js"
export * from "./tree.vue?vue&type=script&lang=js"
import style0 from "./tree.vue?vue&type=style&index=0&id=2b5699f7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5699f7",
  null
  
)

export default component.exports