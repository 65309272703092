<!--
 * @Description: newSearch组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\newSearch.vue
 * @LastEditTime: 2024-12-27 14:53:53
-->
<template>
  <div class="bg-white search-block flex" id="search">
    <div class="left">
      <span v-for="(item, index) in list.filter((item) => item.name != '')" :key="index" class="span">
        <span class="label fs-13 com-c ml-20" v-if="item.type !== 'check'">{{ item.name }}</span>
        <addressd v-if="item.type === 'address'" :item="item" @getadd="getAddress"> </addressd>
        <el-cascader v-else-if="item.type === 'cascader'" clearable v-model="form[item.prop]" :options="item.options" filterable :props="item.props"></el-cascader>
        <el-select v-model="form[item.prop]" v-else-if="item.type === 'select'" clearable>
          <el-option
            :value="item.props ? option[item.props[0]] : option.value"
            v-for="(option, index) in item.options"
            :label="item.props ? option[item.props[1]] : option.label"
            :key="index"
          >
          </el-option>
        </el-select>
        <el-checkbox label v-else-if="item.type === 'check'" v-model="form[item.prop]">{{ item.name }}</el-checkbox>

        <el-date-picker
          clearable
          :picker-options="pickerOptions3"
          v-model="form[item.prop]"
          v-else-if="item.type == 'datetime'"
          type="daterange"
          class="mr-5"
          style="width: 240px"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-date-picker
          clearable
          :picker-options="pickerOptions3"
          v-model="form[item.prop]"
          v-else-if="item.type == 'datetimedetail'"
          type="datetimerange"
          class="mr-5"
          style="width: 240px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :format="'yyyy-MM-dd HH:mm'"
          :value-format="'yyyy-MM-dd HH:mm:ss'"
        >
        </el-date-picker>
        <el-date-picker
          v-model="form[item.prop]"
          v-else-if="item.type == 'date'"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          placeholder="请选择"
          type="date"
          style="width: 240px"
        ></el-date-picker>
        <el-input clearable placeholder="请输入" v-model="form[item.prop]" v-else-if="item.name != ''" style="width: 200px" />
        <el-input type="number" clearable v-else-if="item.type === 'number'" style="width: 200px" />
      </span>
      <slot name="slot" :form="form"></slot>
    </div>
    <el-button @click="search" class="btn" plain>查询</el-button>
    <slot name="btn" :form="form"></slot>
  </div>
</template>
<script>
import addressd from '../../components/address';
export default {
  name: 'newsearch',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    addressd,
  },
  data() {
    return {
      date: [],
      form: {},
      endTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.finish_date_totlal,
      startTime: JSON.parse(sessionStorage.getItem('projectInfo'))?.ment_date,
      pickerOptions3: {
        disabledDate: (time) => {
          return time.getTime() <= new Date(this.startTime).getTime() - 8.64e7 || time.getTime() >= new Date(this.endTime).getTime();
        },
      },
    };
  },
  methods: {
    // 根据传入的参数e，将e.addr赋值给this.form[e.name]
    getAddress(e) {
      this.form[e.name] = e.addr;
    },
    // 将this.form深拷贝一份，然后通过$emit方法将拷贝后的对象e传递给父组件
    search() {
      let e = JSON.parse(JSON.stringify(this.form));
      this.$emit('info', e);
    },
    // 清空绑定数据：遍历this.list，如果item.prop存在，则将this.form[item.prop]设置为null
    clearSearchValues() {
      this.list.forEach((item) => {
        if (item.prop) {
          this.$set(this.form, item.prop, null);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
.label {
  display: inline-block;
  margin-right: 8px;
}

.left {
  max-width: 97%;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.btn {
  margin-left: 10px;
  width: 60px;
  height: 35px;
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #0b2f59;
  color: #0b2f59;
}

.span {
  display: inline-block;
  // min-width: 25%;
  margin-bottom: 5px;
  margin-top: 5px;

  min-width: 270px;
}

/deep/ .el-input__icon {
  line-height: 36px;
}

/deep/ .search-block {
  .money.el-input {
    margin-right: 10px;
    height: 30px;
    padding: 10px;
  }
}

.search-block {
  .el-button {
    padding: 9px 15px;
    border-radius: 2px;
  }
  :deep(.el-input__inner) {
    border: none;
    background: rgba(237, 237, 237, 0.5);
    color: #999999;
  }
  :deep(.el-date-editor) {
    background: rgba(237, 237, 237, 0.5);
    border: none;
    .el-input__inner {
      background: transparent !important;
    }
    .el-range-input,
    .el-input__icon,
    .el-range-separator {
      background: rgba(237, 237, 237, 0.1);
      color: #999999;
    }
    .el-range-separator {
      color: #333333;
    }
  }
}
</style>
