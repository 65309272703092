<!--
 * @Description: newCustomerService组件
 * @Author: fal
 * @FilePath: \pc.ui\src\project\components\newCustomerService.vue
 * @LastEditTime: 2025-01-21 16:34:36
-->
<template>
  <div
    class="customer-service"
    id="customer-service"
    draggable="true"
    :style="{ left: `${x}px`, top: `${y}px` }"
    @dragstart.stop="onDragstart"
    @drag.stop="onDrag"
    @dragend.stop="onDragend"
    @click.stop="show"
  >
    <img src="@/project/assets/icon-service.png" style="width: 48px; height: 48px" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      offx: 0,
      offy: 0,
      x: 0,
      y: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.init);
    this.init();
  },
  methods: {
    init() {
      this.x = 215;
      this.y = window.innerHeight - 100;
    },
    onDragstart(e) {
      const box = document.getElementById('customer-service');
      this.offx = e.pageX - box.offsetLeft;
      this.offy = e.pageY - box.offsetTop;
    },
    onDrag(e) {
      this.x = e.pageX - this.offx;
      this.y = e.pageY - this.offy;
    },
    onDragend(e) {
      this.x = e.pageX - this.offx;
      this.y = e.pageY - this.offy;
    },
    show() {
      this.$emit('show');
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.init);
  },
};
</script>

<style lang="less" scoped>
.customer-service {
  width: 50px;
  height: 50px;
  position: fixed;
  z-index: 100;
  text-align: center;
  cursor: move;
  &:active {
    opacity: 0.5;
  }
  i {
    color: #fff;
    font-size: 30px;
    line-height: 50px;
  }
}
</style>
