<!--
 * @Description: tree节点组件
 * @Author: fal
 * @Date: 2024-11-26 17:12:42
 * @FilePath: \pc.ui\src\project\components\tree\tree.vue
 * @LastEditTime: 2025-01-03 10:10:24
-->
<template>
  <div class="tree-node">
    <div
      :class="{ 'a-selected': select_id == node.id }"
      class="flex node-content"
      ref="text"
      @mouseover="showDropdown = true"
      @mouseleave="showDropdown = false"
      @click="childClicked(node, level)"
    >
      <div class="icon-box" v-if="haveChild">
        <img
          :src="require(node.isExpanded ? '@/project/assets/icon-tree-unfold.png' : '@/project/assets/icon-tree-fold.png')"
          @click.stop="toggleExpanded"
          style="width: 10px; height: 10px"
          class="toggle-icon"
        />
      </div>
      <div v-else>
        <img src="@/project/assets/icon-tree-circle.png" style="width: 10px; height: 10px; margin: 0 5px" />
      </div>

      <span class="label-ellipsis">{{ node.type_name }}</span>
      <div class="opt-content" v-if="edit && ($checkPermission(150) || $checkPermission(151) || $checkPermission(152))">
        <span class="add-node" v-if="showDropdown && (level == 0) & $checkPermission(150)" @click="addAllNode(node, level)">添加子项</span>
        <el-dropdown class="edit-tree-dropdown" v-show="showDropdown && level > 0" trigger="hover">
          <span>
            <img class="showEllipsis" src="@/project/assets/icon-ellipsis.png" style="width: 20px; height: 20px" />
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="!node.ifdetails && level < 5 && $checkPermission(150)">
                <div @click="addAllNode(node, level)" style="width: 100%; cursor: pointer">添加子项</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="node.type_name != '签证及变更' && $checkPermission(151)">
                <div @click="editAllNode(node, level)" style="width: 100%; cursor: pointer">编辑</div>
              </el-dropdown-item>
              <el-dropdown-item v-if="node.type_name != '签证及变更' && $checkPermission(152)">
                <div @click="delAllNode(node, level)" style="width: 100%; cursor: pointer">删除</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

    <div v-if="haveChild && node.isExpanded" class="expand-tree">
      <tree-node v-for="child in node.children" :key="child.id" :node="child" :edit="edit" :level="level + 1" :select_id="select_id" v-bind="$attrs" v-on="$listeners"></tree-node>
    </div>
  </div>
</template>

<script>
export default {
  name: 'treeNode',
  components: { 'tree-node': () => import('./treeNode.vue') },
  data() {
    return {
      showDropdown: false,
    };
  },
  props: {
    node: Object,
    edit: Boolean,
    select_id: String | Number,
    level: { type: Number, default: 0 },
  },
  computed: {
    // 判断是否可以展开
    haveChild() {
      return this.node.children && this.node.children.length;
    },
  },
  methods: {
    // 切换展开状态
    toggleExpanded() {
      // this.node.isExpanded = !this.node.isExpanded;
      this.$emit('update-node', { ...this.node, isExpanded: !this.node.isExpanded });
      // console.log({...this.node, isExpanded: !this.node.isExpanded })
    },
    // 点击情况
    childClicked(node, level) {
      console.log(node, level);
      this.$emit('node-clicked', { node, level });
    },
    // 添加子项
    addAllNode(node, level) {
      console.log('添加', node, level);
      this.$emit('add-node', { node, level });
    },
    // 添加编辑
    editAllNode(node, level) {
      console.log('编辑', node, level);
      this.$emit('edit-node', { node, level });
    },
    // 删除
    delAllNode(node, level) {
      console.log('删除', node, level);
      this.$emit('del-node', { node, level });
    },
  },
};
</script>

<style lang="less" scoped>
.tree-node {
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  .expand-tree {
    margin-left: 10px;
    border-left: 1px dashed rgba(237, 237, 237, 1);
  }
  .icon-box {
    margin: 0 5px;
    width: 10px;
    display: flex;
    cursor: pointer;
  }
  .flex {
    display: flex;
    align-items: center;
    height: 35px;
    justify-content: space-between;
  }

  .flex a {
    padding: 0;
  }

  .flex:hover {
    background: rgba(27, 112, 255, 0.15);
  }

  .flex:hover a {
    background: rgba(27, 112, 255, 0.15);
  }

  .a-selected,
  .a-selected a {
    background: rgba(27, 112, 255, 0.15);
  }
  .rotate {
    transform: rotate(0);
  }
  .label-ellipsis {
    padding-right: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  .add-node {
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    color: #1b70ff;
  }
  .opt-content {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 10px;
  }
  .vertical-line {
    border-left: 1px solid #ccc; /* 竖线样式 */
    height: 100%; /* 竖线高度 */
    position: absolute; /* 绝对定位 */
    left: 10px; /* 根据需要调整位置 */
    top: 35px; /* 根据需要调整位置，确保与图标对齐 */
  }
}
</style>
