/*
 * @Description: axios封装
 * @Author: fal
 * @Date: 2024-11-08 11:28:54
 * @FilePath: \pc.ui\src\util\request.js
 * @LastEditTime: 2024-11-19 20:13:21
 */
import axios from 'axios';
import md5 from 'js-md5';
import vue from '../main';
import { newApiURL } from '../config/index.js';

const instance = axios.create({
  baseURL: newApiURL,
  timeout: 30000,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  let token = '';
  let _isNoProject = false;
  if (sessionStorage.getItem('tokenOrder')) {
    _isNoProject = true;
    token = sessionStorage.getItem('tokenOrder');
  } else {
    token = sessionStorage.getItem('token');
  }
  const timestamp = Date.parse(new Date()) / 1000;

  config.headers = {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'x-requested-with': 'XMLHttpRequest',
    token,
    access_token: md5(timestamp + 'yLJNQ27gBPQozakkjqbnLqTTY3KfOsEpfRn4ePCC5'),
    timestamp,
    urladdress: vue.$route.path,
    isNoProject: _isNoProject,
  };

  return config;
});

instance.interceptors.response.use(
  (response) => {
    const { code, message, data } = response.data;

    if (code === 200) {
      return Promise.resolve(data);
    }

    if (code === -2) {
      vue.$router.push({
        path: '/project/brife',
        query: {
          brifeType: 1,
        },
      });
    } else if (code === 401) {
      sessionStorage.clear();
      vue.$router.push('/login');
      return Promise.reject(new Error('登录已过期，请重新登录'));
    }

    if (vue.$message && typeof vue.$message.error === 'function') {
      vue.$message.error(message);
    }
    return Promise.reject(new Error(message));
  },
  (error) => {
    if (vue.$message && typeof vue.$message.error === 'function') {
      vue.$message.error(error.message || '请求失败');
    }
    return Promise.reject(error);
  }
);

export const request = {
  get: (url, params) => instance.get(url, { params }),
  post: (url, data) => instance.post(url, data),
  put: (url, data) => instance.put(url, data),
  delete: (url) => instance.delete(url),
};

export default instance;
