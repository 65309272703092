<!--
 * @Description: 预警提示组件
 * @Author: fal
 * @Date: 2024-11-21 18:31:27
 * @FilePath: \pc.ui\src\project\components\warnTip.vue
 * @LastEditTime: 2024-12-06 16:44:11
-->
<template>
  <div class="warn-tip" v-if="show">
    <img class="icon" src="@/project/assets/warn-icon.png" />
    <span>{{ tipText }}</span>
    <img class="close" src="@/project/assets/warn-close.png" @click="show = false" />
  </div>
</template>

<script>
export default {
  name: 'warntip',
  props: ['tipText'],
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="less" scoped>
.warn-tip {
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 2px;
  background: rgba(255, 120, 89, 0.1);
  border: 1px dashed rgba(255, 120, 89, 1);
  font-size: 16px;
  font-weight: 500;
  .icon {
    width: 24px;
    height: 24px;
    margin: 0 10px;
  }
  .close {
    cursor: pointer;
    width: 15px;
    height: 15px;
    margin-left: auto;
  }
}
</style>
