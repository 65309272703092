<!--
 * @Description: button组件
 * @Author: fal
 * @Date: 2024-11-25 15:30:20
 * @FilePath: \pc.ui\src\project\components\btn.vue
 * @LastEditTime: 2024-12-13 14:43:30
-->
<template>
  <div class="btn-group">
    <span v-for="item in btnList" :key="item" class="btn">
      <el-button
        v-if="item.split(',').length == 1 || $checkPermission(item.split(',')[1])"
        :type="
          showText(item).indexOf('添加') > -1 || showText(item) == '支付' || showText(item).indexOf('忽略') > -1 ? 'primary' : showText(item).indexOf('审批') > -1 ? 'info' : ''
        "
        class="mr-10"
        @click="goCheck(showText(item))"
        size="small"
      >
        <i class="el-icon-plus" v-if="showText(item).indexOf('添加') > -1 || showText(item) == '支付'"></i>
        {{ showText(item).split(',')[0] }}
      </el-button>
      <input v-if="showText(item).indexOf('导入') > -1 || showText(item).indexOf('替换') > -1" ref="file" @change="importDoc" type="file" id="fileInput" />
    </span>
    <slot name="msg"></slot>
  </div>
</template>

<script>
import { Loading } from 'element-ui';

export default {
  name: 'btn',

  props: {
    btnList: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    // 显示文本
    showText(item) {
      // 将item以逗号分隔成数组
      let arr = item.split(',');
      // 返回数组的第一个元素
      return arr[0];
    },
    goCheck(item) {
      // 触发btn点击事件，并传递按钮名称
      this.$emit('btn', item.split(',')[0]);
    },
    // 导入文档
    importDoc(e) {
      let form = new FormData();
      form.append('file', e.target.files[0]);
      const loadingInstance = Loading.service({
        lock: true,
        text: '正在导入中，请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      this.$request
        .post('/collective/file/upload', form)
        .then((res) => {
          // 触发import-doc事件，并传递返回结果
          this.$emit('import-doc', res);
          // 清空文件选择框
          this.$refs.file[0].value = '';
          // 关闭加载中提示
          loadingInstance.close();
        })
        .catch(() => {
          // 清空文件选择框
          this.$refs.file[0].value = '';
          // 关闭加载中提示
          loadingInstance.close();
        });
    },
  },
};
</script>

<style scoped lang="less">
.btn-group {
  display: flex;
  align-items: center;
  .search-component {
    margin-left: 20px; // 调整搜索组件和按钮之间的间距
  }

  .el-button {
    min-width: 88px;
    height: 35px;
    opacity: 1;
    border-radius: 2px;

    border: 1px solid rgba(11, 47, 89, 1);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 18.48px;
    color: rgba(11, 47, 89, 1);
    padding: 9px 15px;
  }

  .el-button--info {
    background-color: #0b2f59;
    border-color: #0b2f59;
    color: #fff;
  }

  .el-button--primary {
    background-color: #1b70ff;
    border-color: #1b70ff;
    color: #fff;
  }

  .search-block .money.el-input .el-input__inner {
    height: 35px;
  }

  .btn i {
    font-size: 14px;
    font-weight: 900;
  }
}
</style>
